import { NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import Card from '@app/models/post-section/card-rectangle.model';
import { BoxPostTypeOneComponent } from '../box-post-type-one/box-post-type-one.component';
import { CardSquareComponent } from '../card-square/card-square.component';

@Component({
    selector: 'app-box-post-type-two',
    standalone: true,
    imports: [CardSquareComponent, BoxPostTypeOneComponent, NgIf],
    templateUrl: './box-post-type-two.component.html',
    styleUrl: './box-post-type-two.component.sass',
})
export class BoxPostTypeTwoComponent implements AfterViewInit {
    @Input() productsOrServices!: Card[];
    @Input() backgroundBoxPost!: string;
    @ViewChild('container') container!: ElementRef;
    @Output() maxHeight = new EventEmitter<number>();

    constructor() {}

    ngAfterViewInit(): void {
        this.emitMaxHeight();
    }

    emitMaxHeight(): void {
        this.maxHeight.emit(this.container.nativeElement.offsetHeight);
    }
}

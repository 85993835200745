<div #container class="box-post-type-one">
    <ng-container *ngIf="productsOrServices.length === 1; else elseBlock">
        <app-card-square
            [productsOrService]="productsOrServices[0]"
        ></app-card-square>
    </ng-container>
    <ng-template #elseBlock>
        <ng-container
            *ngFor="
                let productsOrService of productsOrServices;
                let last = last;
                let i = index
            "
        >
            <div [class]="'index-' + i">
                <app-card-rectangle
                    [productsOrService]="productsOrService"
                ></app-card-rectangle>
            </div>
            <div *ngIf="!last" class="box-post-space"></div>
        </ng-container>
    </ng-template>
</div>

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { NavigationService } from '@app/services/navigation/navigation.service';
import { OpenModalService } from '@app/services/open-modal/open-modal.service';

export const loginGuard: CanActivateFn = (route, state) => {
    const openModalService = inject(OpenModalService);
    const navigationService = inject(NavigationService);

    if (!openModalService.openModalLogin()) {
        navigationService.navigatePage('home');
        return false;
    }

    return true;
};

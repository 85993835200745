<div #container class="box-post-type-two">
    <ng-container *ngIf="productsOrServices.length === 1; else elseBlock">
        <app-card-square
            [productsOrService]="productsOrServices[0]"
        ></app-card-square>
    </ng-container>
    <ng-template #elseBlock>
        <app-box-post-type-one
            [productsOrServices]="productsOrServices"
        ></app-box-post-type-one>
    </ng-template>
</div>

import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import Card from '@app/models/post-section/card-rectangle.model';
import { CardRectangleComponent } from '../card-rectangle/card-rectangle.component';
import { CardSquareComponent } from '../card-square/card-square.component';

@Component({
    selector: 'app-box-post-type-one',
    standalone: true,
    imports: [CardRectangleComponent, CardSquareComponent, CommonModule],
    templateUrl: './box-post-type-one.component.html',
    styleUrl: './box-post-type-one.component.sass',
})
export class BoxPostTypeOneComponent implements AfterViewInit {
    @Input() productsOrServices!: Card[];
    @ViewChild('container') container!: ElementRef;
    @Output() maxHeight = new EventEmitter<number>();

    constructor() {}

    ngAfterViewInit(): void {
        this.emitMaxHeight();
    }

    emitMaxHeight(): void {
        this.maxHeight.emit(this.container.nativeElement.offsetHeight);
    }
}

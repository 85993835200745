import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core';
import { textsForExchanges } from '@app/arrays/textsExchanges';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import { BoxPostImageComponent } from '../box-post-image/box-post-image.component';

registerSwiperElements();

@Component({
    selector: 'app-box-carousel-post',
    standalone: true,
    imports: [BoxPostImageComponent],
    templateUrl: './box-carousel-post.component.html',
    styleUrl: './box-carousel-post.component.sass',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BoxCarouselPostComponent implements AfterViewInit {
    @Input() interaction: string;
    @Input() owner: any;
    @Output() loadingData: EventEmitter<void>;
    dataSource: any;

    public textsForExchanges = textsForExchanges;
    public carouselSizes: number[] = [];
    public finalSize: number = 0;
    private dataReceived: boolean = false; // Variable de estado para verificar si se han recibido datos
    public boxPostHeadTitle: string = '';

    private readonly cdr = inject(ChangeDetectorRef);

    constructor() {
        this.loadingData = new EventEmitter<void>();
        this.interaction = '';
        this.dataSource = [];
        this.owner = {};
    }

    @Input() set dataSourceFilter(dataSource: any[]) {
        setTimeout(() => {
            if (dataSource.length === 0) return;

            this.dataSource = dataSource
                .map((itrData: any) => {
                    const chunkSize = 5;
                    const chunkedPublications = [];

                    for (
                        let i = 0;
                        i < itrData.dataSource.length;
                        i += chunkSize
                    ) {
                        const chunk = itrData.dataSource.slice(
                            i,
                            i + chunkSize
                        );
                        const newData = { ...itrData, dataSource: chunk };
                        chunkedPublications.push(newData);
                    }

                    return chunkedPublications;
                })
                .flat();

            this.dataReceived = true; // Indicar que se han recibido datos
            this.cdr.detectChanges();
        }, 250);
    }

    getComponentHeight($event: number): void {
        this.carouselSizes.push($event);
        if (this.dataReceived) {
            // Verificar si se han recibido datos antes de calcular el máximo
            this.finalSize = Math.max(...this.carouselSizes) + 20;
            this.finalSize = this.finalSize < 300 ? 300 : this.finalSize; //min size if less than 300
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loadingData.emit();
        }, 100);
    }
}

<div #container class="box-post-type-three">
    <ng-container *ngIf="productsOrServices.length === 1; else elseBlock">
        <app-card-square
            [productsOrService]="productsOrServices[0]"
        ></app-card-square>
    </ng-container>
    <ng-template #elseBlock>
        <ng-container *ngIf="productsOrServices.length > 0">
            <div class="d-flex gap-1">
                <app-card-square
                    [productsOrService]="productsOrServices[0]"
                ></app-card-square>
                <ng-container *ngIf="productsOrServices[1]">

                    <app-card-square
                        [productsOrService]="productsOrServices[1]"
                    ></app-card-square>
                </ng-container>
            </div>

            <ng-container *ngIf="productsOrServices[2]">
                <div class="d-flex gap-1">
                    <app-card-square
                        [productsOrService]="productsOrServices[2]"
                    ></app-card-square>
                    <ng-container *ngIf="productsOrServices[3]">
                        <app-card-square
                            [productsOrService]="productsOrServices[3]"
                        ></app-card-square>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="productsOrServices[4]">
                <app-card-rectangle
                    class="mt-05"
                    [productsOrService]="productsOrServices[4]"
                ></app-card-rectangle>
            </ng-container>
        </ng-container>
    </ng-template>
</div>

<div
    (click)="openPublication(productsOrService.id)"
    class="card-square-product-or-service">
    <div
        class="content"
        [ngClass]="{ 'without-image': !productsOrService.imageUrl }">
        @if (productsOrService.imageUrl) {
            <div class="image">
                <img [src]="productsOrService.imageUrl" alt="image" loading="lazy" />
            </div>
        }
        <div class="title">
            <p>{{ productsOrService.title }}</p>
        </div>
    </div>
</div>
